/* You can add global styles to this file, and also import other style files */
@import './app/scss-util/mixins';

@font-face{
    font-family:"Futura-LT-W01-Light";
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix");
    src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/2b40e918-d269-4fd9-a572-19f5fec7cd7f.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/26091050-06ef-4fd5-b199-21b27c0ed85e.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cca525a8-ad89-43ae-aced-bcb49fb271dc.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/88cc7a39-1834-4468-936a-f3c25b5d81a1.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/14d6bf5d-15f1-4794-b18e-c03fb9a5187e.svg#14d6bf5d-15f1-4794-b18e-c03fb9a5187e") format("svg");
}

body {
  font-family: futura-lt-w01-light,sans-serif;;
  padding-bottom: 5em;
}

h1,h2,h3,h4,h5,h6 {
  color: rgb(80,141,179);

}

.btn-primary {
  background-color: rgb(80,141,179);
}


a, #billingAccounts {
  color: rgb(80,141,179);
}


.videoIframe {

  height: 262px;

  @include respond-above(sm) {
        height: 262px;
  }

  @include respond-above(md) {
        height: 431px;
  }
}

html .billing-details-page .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #1d6491;
  --mdc-switch-selected-handle-color: #1d6491;
  --mdc-switch-selected-hover-state-layer-color: #1d6491;
  --mdc-switch-selected-pressed-state-layer-color: #1d6491;
  --mdc-switch-selected-focus-handle-color: #1d6491;
  --mdc-switch-selected-hover-handle-color: #1d6491;
  --mdc-switch-selected-pressed-handle-color: #1d6491;
  --mdc-switch-selected-focus-track-color: #1d649182;
  --mdc-switch-selected-hover-track-color: #1d649182;
  --mdc-switch-selected-pressed-track-color: #1d649182;
  --mdc-switch-selected-track-color: #1d649182;
}

html .billing-details-page .mat-mdc-paginator-page-size-select {
    margin: 0 4px;
    width: 48px;
}

html .billing-details-page .mat-mdc-text-field-wrapper {
    padding: 0px 9px;
}

html .billing-details-page .mat-mdc-option.mdc-list-item {
  font-size: 15px;
  margin: 0 auto;
  text-align: center;
}

html .billing-details-page div.mat-mdc-select-panel {
  padding: 0px 0;
}


html .billing-details-page .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon,
html .billing-details-page .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  display: none;
}

.black-tooltip {
   > .tooltip-inner {
    background-color: black;
  }


}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: black; 
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}
